.shoplist-container {
    margin: auto;

    @include Responsive(XL) {
        width: 75%;
    }
}

.btn-back {
    display: inline-block;
    margin-block: 25px;
    padding: 5px;
    cursor: pointer;
    padding-left: 30px;

    &>span {
        display: inline-block;
        position: relative;
        // &::after {
        //     background: $TEXT_COLOR-PRIMARY;
        //     width: 100%;
        //     height: 1px;
        //     position: absolute;
        //     bottom: -0.5px;
        //     left: 0;
        //     display: block;
        //     content: '';
        // }
    }
}

.shop-item-container {
    .shop-item-name {
        padding: 10px 30px;
        border-radius: 4px;
        background: #f6f6f6;
        color: #f12b59;
        font-weight: bold;
        font-size: 4dvw;

        @include Responsive(MD) {
            font-size: 30px;
        }
    }

    .shop-item-info {
        margin-block: 2px;

        @include Responsive(SM) {
            margin-block: 7px;
        }

        &>img {
            margin-right: 20px;
            max-width: 33px;
        }

        &>span {
            font-size: 16px;
        }
    }

    .shop-item-link {
        color: #fff;
        padding: 6px 30px 8px 30px;
        background: $COLOR-PRIMARY;
        border-radius: 100px;
        // font-weight: bold;
        font-size: 16px;
        vertical-align: middle;
    }
}