@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;600;700&display=swap');
@import "_variable.scss";
@import "_mixin.scss";
@import "home.scss";
@import "shop.scss";
@import "shops.scss";
@import "shoplist.scss";
@import "ranking.scss";

/*------------------------------------------------------------
	デフォルトスタイル
------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
}

html {
  /* font-size: 62.5%; */
  font-size: 14px;
  /* ルート要素のフォントサイズを1rem=14pxと定義する */
}

body,
table,
input,
textarea,
select,
option {
  font-family: 'Noto Sans JP', sans-serif;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

a,
a:link {
  color: $TEXT_COLOR-DARK;
  text-decoration: none;
  transition: .3s;
}

a:visited {
  color: $TEXT_COLOR-DARK;
}

a:hover {
  color: $TEXT_COLOR-DARK;
}

a:active {
  color: $TEXT_COLOR-DARK;
}

/*------------------------------------------------------------
	レイアウト
------------------------------------------------------------*/
body {
  color: $TEXT_COLOR-DARK;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  -webkit-text-size-adjust: none;
  background-color: #FFF;
}

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

#container {
  text-align: left;
}

a[href^="tel:"] {
  cursor: default;
  pointer-events: none;
}

@media all and (min-width: 897px) {
  .sp {
    display: none !important;
  }
}

@media all and (max-width: 896px) {
  body {
    min-width: inherit;
    font-size: 14px;
  }

  a:hover,
  a:hover img {
    opacity: 1 !important;
  }

  .pc {
    display: none !important;
  }

  a[href^="tel:"] {
    cursor: pointer;
    pointer-events: auto;
  }
}

#root {
  overflow: hidden;
}

/*------------------------------------------------------------
	ヘッダー (Header)
------------------------------------------------------------*/

header {
  padding: 30px 60px;
  z-index: 9;

  .hBox {
    width: 100%;
    display: inline-block;

    h1 {
      width: 300px;
      float: left;
      margin-bottom: 0;
    }

    #gNavi {
      float: right;
      padding-top: 10px;

      ul {
        text-align: right;
        padding-top: 10px;

        li {
          display: inline-block;
          text-align: center;

          &:not(:first-child) {
            margin-left: 44px;
          }

          a {
            display: block;
            font-size: 18px;
            color: #239043;

            &:hover {
              color: #8DC556;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 897px) {
  .menuBox {
    display: none !important;
  }
}

@media all and (max-width: 896px) {
  header {
    padding: 10px;
    background-color: #fff;

    .hBox {
      max-width: inherit;

      h1 {
        width: 200px;
      }

      #gNavi {
        display: none;
      }
    }

    .menu {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 10px;
      top: 20px;
      z-index: 9;
      cursor: pointer;

      span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: absolute;
        left: 5px;
        width: 30px;
        height: 2px;
        background-color: #8DC556;
        border-radius: 2px;

        &:nth-of-type(1) {
          top: 10px;
        }

        &:nth-of-type(2) {
          top: 17px;
        }

        &:nth-of-type(3) {
          top: 24px;
        }
      }

      &.on {
        span:nth-of-type(1) {
          -webkit-transform: translateY(8px) rotate(-45deg);
          transform: translateY(8px) rotate(-45deg);
        }

        span:nth-of-type(2) {
          right: 50%;
          opacity: 0;
          -webkit-animation: active-menu-bar02 .8s forwards;
          animation: active-menu-bar02 .8s forwards;
        }

        span:nth-of-type(3) {
          -webkit-transform: translateY(-6px) rotate(45deg);
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
  }

  .menuBox {
    display: none;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 8;
    transition: all .3s;

    &.show {
      display: block;
      opacity: 1;
    }

    ul {
      padding: 45px 15px;

      li {
        &:first-child {
          span {
            border-top: none;
          }
        }

        span {
          padding: 15px;
          display: block;
          text-align: center;
          border-top: 1px solid #8DC556;
          font-size: 16px;
          color: #155c47 !important;

          span {
            display: block;
          }
        }

        &:last-child span {
          border-bottom: 1px solid #8DC556;
        }
      }
    }
  }
}

/*------------------------------------------------------------
	pagePath
------------------------------------------------------------*/
#pagePath {
  margin-bottom: 24px;
}

#pagePath li {
  display: inline;
  font-size: 1.2rem;
}

#pagePath li a:hover {
  text-decoration: underline;
}

@media all and (max-width: 896px) {
  #pagePath li a:hover {
    text-decoration: none;
  }

}

/*------------------------------------------------------------
	フッター
------------------------------------------------------------*/
footer {
  padding: 55px 60px 19px 60px;
  margin-top: 20px;
  position: relative;

  .pageTop {
    position: absolute;
    right: 100px;
    top: -60px;
    width: 50px;
  }

  .pageTop a:hover {
    opacity: 0.7;
  }

  .footer-top-container {
    margin-bottom: 60px;
    width: 100%;
    display: inline-block;

    .footer-left {
      float: left;

      ul {
        width: fit-content;
        display: inline-block;
        vertical-align: top;

        &:first-child {
          margin-right: 100px;
        }

        li a {
          color: #4D4D4D;
        }

        li:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    .footer-right {
      float: right;

      img {
        width: 350px;
        max-width: 100%;
      }
    }
  }

  .copyright {
    text-align: center;
    font-size: 15px;
    padding: 25px 0px 10px 0px;
    letter-spacing: 0.1em;
    color: #6B5C4A;
  }
}

//Responsive
@media all and (max-width: 768px) {
  footer {
    margin: 0;
    padding: 25px;

    .pageTop {
      right: 20px;
      top: -25px;
    }

    .footer-top-container {
      margin-bottom: 40px;

      .footer-left {
        float: none;
        margin-bottom: 20px;

        ul {
          display: inline-block;
          vertical-align: top;
          width: 50%;

          &:first-child {
            margin-right: 0;
          }

          li a {
            color: #4D4D4D;
            font-size: 13px;
          }

          li:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }

      .footer-right {
        float: none;
        text-align: center;
      }
    }

    .copyright {
      font-size: 3vw;
      // font-size: 1rem;
    }
  }
}

//@media all and (max-width: 896px) {
//  .pageTop {
//    right: 10px;
//    top: -42px;
//    width: 40px;
//  }
//}


//general
/*------------------------------------------------------------
	汎用スタイル
------------------------------------------------------------*/
/* clearfix */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* flex */
.flex,
.flexA,
.flexB,
.flexC {
  display: flex;
  flex-wrap: wrap;
}

.flexA {
  justify-content: space-around;
}

.flexB {
  justify-content: space-between;
}

.flexC {
  justify-content: center;
}

/*------------------------------------------------------------
	fixLink
------------------------------------------------------------*/
.fixLink {
  display: none;
  position: fixed;
  right: 0;
  top: 80px;
  width: 100px;
  z-index: 2;
}

.fixLink a:hover {
  opacity: 0.7;
}

@media all and (max-width: 896px) {
  .fixLink {
    width: 40px;
  }

}

/*------------------------------------------------------------
	content
------------------------------------------------------------*/
.content {
  width: 90%;
  min-width: 1000px;
  margin: 0 auto;
}

.content01 {
  width: 1050px;
}

@media all and (max-width: 896px) {
  .content {
    width: auto !important;
    margin: 0 15px;
  }

}

/*------------------------------------------------------------
	headLine01
------------------------------------------------------------*/
.headLine01 {
  margin-bottom: 62px;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  font-family: 'Noto Serif JP', serif;
}

.headLine01 span {
  margin-top: 14px;
  padding-top: 11px;
  display: block;
  color: #8DC556;
  font-size: 2.5rem;
  font-weight: 400;
  border-top: 2px solid #BFBFBF;
}

@media all and (max-width: 896px) {
  .headLine01 {
    margin-bottom: 15px;
    font-size: 1.7rem;
  }

  .headLine01 span {
    margin-top: 3px;
    padding-top: 3px;
    font-size: 1.5rem;
  }

}

/*------------------------------------------------------------
	headLine02
------------------------------------------------------------*/
.headLine02 {
  margin-bottom: 67px;
  padding: 98px 0 107px;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.1em;
  //background: url("../../img/common/line01.png") no-repeat center top, url("../../img/common/line02.png") no-repeat center bottom;
  background-size: 290px auto, 290px auto;
  font-family: 'Noto Serif JP', serif;
}

@media all and (max-width: 896px) {
  .headLine02 {
    margin-bottom: 20px;
    padding: 50px 0;
    font-size: 1.7rem;
    background-size: 200px auto, 200px auto;
  }

}

/*------------------------------------------------------------
	comBtmUl
------------------------------------------------------------*/
.comBtmUl li {
  margin: 0 17px;
}

.comBtmUl li a:hover {
  opacity: 0.7;
}

@media all and (max-width: 896px) {
  .comBtmUl {
    display: inherit;
  }

  .comBtmUl li {
    margin: 0;
    text-align: center;
  }

  .comBtmUl li:first-child {
    margin-bottom: 15px;
  }

}

button:focus {
  outline: none !important;
}

.modal-90w {
  max-width: 90% !important;
}

.modal-header {
  background-color: #E7F1D9;
}

.shop-flower-image {
  width: 47%;
}

//end general


//Top css
.top-form {
  background-color: #E5EBE4;
  text-align: center;
  padding: 20px;
  padding-top: 10px;

  .form-text {
    color: #175D48;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .input-group {
    input {
      padding: 5px 25px;
      height: 50px;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      font-size: 20px;
    }

    .btn {
      background-color: #155C47;
      border-color: #155C47;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
      width: 60px;

      img {
        width: 20px;
      }
    }
  }

  .btn-search {
    background-color: #155C47;
    border-color: transparent;
    color: white;
    width: 100%;
    padding: 6px 12px;
    padding-bottom: 8px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-top: 1rem;
    }

    &>span {
      vertical-align: baseline;
    }

    .btn-search-text {
      font-size: 16px;
    }
  }
}

.w-100 {
  width: 100%;
}

.text-intro-1 {
  color: $COLOR-PRIMARY;
  font-size: 35px;
  margin-bottom: 30px;
}

.intro-separator {
  width: 130px;
  height: 10px;
  background-color: #D9F2DB;
  margin: 0 auto 30px;
}

.text-intro-2 {
  // color: #155C47;
  font-size: 17px;
}

.image-text-container {
  padding: 50px 50px 25px 50px;

  .text-image-1 {
    color: $COLOR-PRIMARY;
    // color: #00523F;
    font-size: 30px;
    margin-bottom: 30px;
  }

  .text-image-2 {
    // color: #00523F;
    font-size: 16px;
    // font-size: 14px;
    line-height: 24px;
  }
}

//End Top css

.mt-35 {
  margin-top: 35px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

#page_header {
  // padding: 30px 60px;

  .logo-container {
    text-align: right;

    a {
      img {
        width: 200px;
      }
    }
  }
}

#page_footer {
  text-align: center;

  img {
    width: 200px;
  }
}

.shop-info {
  padding-left: 30px;
  padding-right: 30px;

  .text-shop-name {
    color: #f12b59;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 35px;
  }

  .btn-go-shop {
    color: #9EC389;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 250px;
    margin: 0 auto;
    background-color: transparent;
    border: 2px solid #9EC389;
    font-size: 19px;
    font-weight: 600;
    display: block;
    position: relative;

    img {
      position: absolute;
      right: 15px;
      width: 23px;
      margin-left: 13px;
    }
  }

  .shop-info-line {
    margin-bottom: 10px;

    >img {
      display: inline-block;
    }

    >div {
      display: inline-block;
      padding-top: 6px;
      padding-left: 6px;
      max-width: calc(100% - 35px);
    }
  }
}

.not-found-container {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;

  img {
    width: 400px;
  }
}

.google-pin {
  position: relative;

  .shop-address {
    width: 200px;
    position: absolute;
    top: -28px;
    left: 20px;
    font-size: 16px;
    color: darkred;
  }

  img {
    position: absolute;
    top: -34px;
    left: -10px;
    width: 25px;
  }
}

.fb-iframe {
  text-align: center;
}

.twitter-iframe {
  height: 500px !important;
}

.instagram-iframe>div {
  overflow-y: auto;
  height: 455px !important;
}

.sns-icon-container {
  width: fit-content;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  vertical-align: top;

  .sns-icon {
    width: 90px;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.fl-share-button {
  font-weight: 400 !important;
  min-width: 75px !important;
  padding: 4px 7px !important;
  font-size: 13px !important;

  :focus {
    color: #ffffff !important;
  }
}

.fl-facebook-share-button {
  cursor: pointer !important;
  border: 0px !important;
  color: #ffffff !important;
  background-color: #4C67A1 !important;

  &:hover {
    color: #ffffff !important;
    background-color: #395591 !important;
  }
}

.fl-twitter-share-button {
  cursor: pointer !important;
  border: 0px !important;
  color: #ffffff !important;
  background-color: #1da2f2 !important;

  &:hover {
    color: #ffffff !important;
    background-color: #1a97e4 !important;
  }
}

.fl-line-share-button {
  cursor: pointer !important;
  border: 0px !important;
  color: #ffffff !important;
  background-color: #00b833 !important;

  &:hover {
    color: #ffffff !important;
    background-color: #00ab30 !important;
  }
}

#home footer {
  background-color: #e8f1d9;
  padding: 20px 60px 19px 60px;
  margin-top: 40px;
}

footer {
  #fLogoBlock {
    justify-content: space-between;
    align-items: center;

    // align-items: flex-start;
    // @include Responsive(SM) {
    //   align-items: center;
    // }

    .flogo {
      min-width: 18%;
      // padding-left: 20px;

      // @include Responsive(SM) {
      //   padding-left: 0;
      // }

      img {
        max-width: 250px;
        width: 100%;
        height: auto;
      }
    }

    .fNavi {
      align-self: flex-start;

      @include Responsive(SM) {
        align-self: center;
      }

      .footer-link {
        display: block;
        padding-left: 20px;

        @include Responsive(SM) {
          display: flex;
          padding-left: 0;
        }

        a {
          color: $COLOR-PRIMARY;
          display: block;

          padding: 8px 0;

          @include Responsive(SM) {
            padding: 15px 20px;
          }

          &:first-child {
            padding-top: 3px;

            @include Responsive(SM) {
              padding-top: 15px;
            }
          }
        }
      }
    }

    .fSociety {
      min-width: 18%;
      align-self: center;

      a {
        font-size: 20px;
        padding-inline: 15px;
        color: $COLOR-PRIMARY;
      }

      .sns-img {
        height: 20px;
        width: auto;
      }
    }
  }
}

@media all and (max-width: 1200px) {
  footer {
    #fLogoBlock {
      .flogo {
        min-width: 13%;
      }

      .fSociety {
        min-width: 13%;
      }
    }
  }
}

@media all and (max-width: 1100px) {
  footer {
    #fLogoBlock {
      .flogo {
        min-width: 11%;
      }

      .fSociety {
        min-width: 11%;
      }
    }
  }
}

@media all and (max-width: 1050px) {
  footer {
    #fLogoBlock {
      .flogo {
        min-width: 7%;
      }

      .fSociety {
        min-width: 7%;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .col-lg-20-percent {
    max-width: 20%;
  }
}

@media all and (max-width: 992px) {
  #home footer {
    padding: 20px 00px 19px 00px;
  }

  #fLogoBlock {
    flex-direction: column;

    .fSociety {
      >div {
        justify-content: center;
      }
    }
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.information-banner {
  max-height: 245px;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.information-banner>img {
  // position: absolute;
  opacity: 0.5;
  width: 100%;
  max-height: 245px;
  z-index: -1;
}

.information-header {
  text-transform: uppercase;
  color: #155c46;
  font-size: 24px;
  font-family: 'Gideon Roman', serif;
  position: absolute;

  @include Responsive(MD) {
    font-size: 48px;
  }
}

.information-container {
  margin: auto;
  min-height: 600px;
  padding: 0 15px;
}

.information-title {
  color: #155C47;
  text-align: center;
  font-size: 18px;
  margin-block: 55px;

  // text-shadow: 0 0 1px #155C47;
  @include Responsive(MD) {
    font-size: 20px;
  }
}

.information-content {
  text-align: justify;
  margin-bottom: 40px;
  font-weight: 400;
  line-height: 1.7;
  font-size: 15px;

  @include Responsive(MD) {
    font-size: 16px;
  }

  .information-content-special {
    margin-left: -8px;
  }
}

.text-primary {
  color: $TEXT_COLOR-PRIMARY !important;
}