.ranking-container {
    padding: 15px 0;
    margin: auto;
    @include Responsive(XL) {
        width: 75%;
    }
}

#ranking-table {
    text-align: center;

    tbody > tr:nth-of-type(odd) {
        @include Responsive(LG) {
            background: rgba(0,0,0,.05);
        }
    }

    th {
        border: 1px solid #dee2e6;
        padding: 0.75rem;
    }

    td {
        padding: 0.75rem;
        border: 1px solid #dee2e6;
        &>img {
            @include Responsive(LG) {
                width: 200px;
            }
        }
    }
}

/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
    width: 100%;
  }
  
  .responsiveTable td .tdBefore {
    display: none;
  }
  
  @media screen and (max-width: 991px) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */
  
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }
  
    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      border-bottom: 2px solid #333;
    }
  
    .responsiveTable tbody tr {
      border: 1px solid #000;
      padding: .25em;
    }
  
    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  
    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;
  
      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }
  }
  