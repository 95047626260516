// Khai báo kích thước tương ứng với màn hình thiết bị

$XS:     0px;   
$SM:     576px;  
$MD:    768px;   
$LG:    992px;   
$XL:   1200px;     

// media queries

@mixin Responsive($canvas) {
  @if $canvas == XS {
   @media only screen and (min-width: $XS) { @content; } 
  }
  @else if $canvas == SM {
   @media only screen and (min-width: $SM) { @content; } 
  }
  @else if $canvas == MD {
   @media only screen and (min-width: $MD) { @content; } 
  }
  @else if $canvas == LG {
    @media only screen and (min-width: $LG) { @content; } 
   }
  @else if $canvas == XL {
   @media only screen and (min-width: $XL) { @content; } 
  }
}
