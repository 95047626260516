#home {
    &.container {
        max-width: 1800px;
        padding: 0px !important;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        .fl-facebook-block {
            .fl-facebook-left {
                width: calc(100% - 515px) !important;
                padding-right: 15px;
                padding-left: 15px;
            }

            .fl-facebook-right {
                padding-left: 15px;
                width: 515px !important;
                text-align: center;
            }
        }
    }

    @media (max-width: 767.98px) {
        .fl-facebook-block {
            .fl-facebook-left {
                width: 100% !important;
            }

            .fl-facebook-right {
                width: 100% !important;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        .fl-facebook-block {
            .fl-facebook-left {
                width: calc(100% - 380px) !important;
            }

            .fl-facebook-right {
                width: 380px !important;
                text-align: center;
            }
        }
    }
}


.text-about-home {
    padding: 50px 50px 25px 50px;

    .text-intro-1 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .text-intro-2 {
        font-size: 16px;
        line-height: 24px;
    }
}

.img-4 {
    width: 100%;
    height: 100%;
    position: relative;

    @include Responsive(MD) {
        position: absolute;
    }

    @include Responsive(XL) {
        position: relative;
    }
}

.image-text-container-4 {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    @include Responsive(XL) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .text-image-4 {
        padding: 0 50px 0 35px;
        margin: 30px 0;
        text-align: justify;

        @include Responsive(MD) {
            position: relative;
            padding: 20px 0 20px 50px;
            color: $TEXT_COLOR-DARK;
            font-size: 16px;
            line-height: 1.5;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 15px;
                background: #fff4;
            }
        }
    }
}

.home-banner-event {
    margin-inline: auto;
    width: 70%;

    @include Responsive(MD) {
        width: 100%;
    }
}

.icon-search {
    width: 30px;
    height: 20px;
    margin-left: 15px;
}

.home-form-search {
    font-size: 16px !important;

    &::placeholder {
        color: #a1beb5;
        text-align: center;
    }
}