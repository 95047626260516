.search-container {
    padding: 0;
    margin: auto;

    @include Responsive(XL) {
        padding: 50px 0;
    }

    @include Responsive(XL) {
        width: 75%;
    }
}

.search-group {
    background: #f1f6ef;
    border-radius: 8px;
    padding: 40px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.search-group .form-control {
    transition: 0.3s;
    color: $TEXT_COLOR-PRIMARY;

    &:focus {
        box-shadow: none;
        border-color: $TEXT_COLOR-PRIMARY;
    }

    &::placeholder {
        color: #a1beb5;
        text-align: center;
    }
}

.search-group .input-group {
    max-width: 500px;

    .input-group-append .btn {
        background: $TEXT_COLOR-PRIMARY;
        border-color: $TEXT_COLOR-PRIMARY;

        &:hover {
            background: #2a6151;
        }

        &:active {
            background: #295d4e;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
}

.search-line {
    border: 1px solid $COLOR-PRIMARY;
    margin: 0;
}

.search-city {
    color: $COLOR-PRIMARY;
    justify-content: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    background: #f1f6ef;
    font-weight: bold;
    padding: 4px 0 5px 0;
    // padding: 5px 0;
}

.search-list-arrow {
    color: #f00;
}

.search-list-place {
    margin-left: 15px;
    color: $COLOR-PRIMARY;
    font-size: 17px;
}

.gg-map-container {
    height: 500px;
}

.shop-title {
    color: $TEXT_COLOR-DARK;
}

.shop-marker-popup {
    position: absolute;
    bottom: 10px;
    width: 300px;
    background: #fff;
    left: 50%;
    transform: translate(-50%, -30px);
    padding: 1rem;
    z-index: 1000;
    border-radius: 15px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .3);

    hr {
        border-top: 1px solid #000;
        margin: 10px 0;
    }
}

.shop-marker-title {
    color: $TEXT_COLOR-PRIMARY;
    // font-size: medium;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    line-height: 25px;
    font-size: 16px;
    text-align: center;
    margin: 8px 0;
}

.shop-marker-image {
    width: 100%;
    height: 150px;
    background-position: center,
}

.shop-marker-content {
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
}

.shop-marker-close {
    position: absolute;
    padding: 11px;
    font-size: 20px;
    top: 0;
    right: 0;
}

.shop-marker-arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    position: absolute;
    top: 100%;
    left: calc(50% + 5px);
}

.shop-marker-location {
    width: 30px;
    height: 40px;
    position: relative;
    top: -40px;
    height: -30px;
}

.gg-map-input-group {
    max-width: 570px;
    margin-left: auto;
    margin-bottom: 1rem;
    // padding-inline: 15px;

    .map-input-search.form-control {
        transition: 0.3s;
        color: $TEXT_COLOR-PRIMARY;

        &:focus {
            box-shadow: none;
            border-color: $TEXT_COLOR-PRIMARY;
        }

        &::placeholder {
            color: #a1beb5;
            text-align: center;
        }
    }

    .input-group-append .btn {
        transition: 0.3s;
        background: $TEXT_COLOR-PRIMARY;
        border-color: $TEXT_COLOR-PRIMARY;

        &:hover {
            background: #2a6151;
            border-color: #2a6151;
        }

        &:active {
            background: #295d4e;
            border-color: #295d4e;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
}

.search-result {
    background: #eee;
    margin-top: 5px;
    overflow-y: auto;
    max-height: 461.5px;

    .search-place-item {
        padding: 15px;
        margin-top: 2px;
        background: #f1f6ef;
        cursor: pointer;
    }
}

.button-modal-search {
    cursor: pointer;
    display: block;
    background: $TEXT_COLOR-PRIMARY;
    text-align: center;
    padding: 0.5rem;
    color: #fff;
    font-size: 16px;
    margin-top: 15px;
    border-radius: 4px;
}